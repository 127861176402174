
import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Dashboard(props) {
    const [subjects, setSubjects] = useState([])
    const breadcrumb = props.nm
    const [expda, setExpDa] = useState(new Date());
    //const [sid,setSid] = useState('');
    const [logstatus, setLogstatus] = useState(() => {
        const st = localStorage.getItem("login");
        return st || ""
    });
    const [uid, setUid] = useState(() => {
        const st = localStorage.getItem("info");
        return st || ""
    });

    const [sid, setSid] = useState(() => {
        const st = localStorage.getItem("subid");
        return st || ""
    });

    const [days, setDays] = useState(10);

    function isLog() {
        if (logstatus == "") {
            window.location.href = "/login";
        }
        else {
            const sts = localStorage.getItem('status');
            if (sts) {
                toast('Login Successfull');
                localStorage.removeItem('status');
            }
            const msg = localStorage.getItem('msg')
            if (msg) {
                toast(msg);
                localStorage.removeItem('msg');
            }
        }
    }
    function fetchSubjects() {
        axios.get('https://api.fab-g.com/api/fetchSubscription', { headers: { "Authorization": `Bearer ${uid}` } })
            .then((res) => {
                const data = res.data.sub;
                // console.log(data[0].sub_code);
                if (data.length > 0) {
                    localStorage.setItem('scode', data[0].sub_code);
                    let dt = new Date()
                    let edt = new Date(data[0].sub_edate);
                    // setExpDa(data[0].sub_edate);
                    setExpDa(edt)
                    var time_difference = edt.getTime() - dt.getTime();

                    //calculate days difference by dividing total milliseconds in a day  
                    var days_difference = parseInt(time_difference / (1000 * 60 * 60 * 24))+1;
                    setDays(days_difference);
                }
                setSubjects(res.data.cate);
            })
    }
    function AddCC(urlOfTheLibrary) {
        const script = document.createElement('link');
        script.href = urlOfTheLibrary;
        script.rel = "stylesheet";
        script.type = "text/css";
        document.head.appendChild(script);
    }
    function handleUnlock() {
        window.location.href = "/subscribe"


    }
    function handleQb(e) {
        if (e.target.value != "All")
            localStorage.setItem('subid', e.target.value)
        else {
            localStorage.setItem('subid', "");
        }
        setSid(e.target.value);

        console.log(e.target.value)
        window.location.reload();
    }
    useEffect(() => {
        isLog()

        fetchSubjects();
    }, [])
    return (
        <React.Fragment>
            {AddCC('/assets/css/Sidebar.css')}
            <ToastContainer />
            <header className="header">
                <div className="header__container border-bottom">
                    <img src="/assets/images/logo (1).png" alt="" className="header__img" />
                    <a href="/" className="header__logo">
                        <img src="/assets/images/logo (1).png" alt="" />
                        <span style={{ marginLeft: 10, fontSize: 12, color: '#0d5c63' }}>Fab-g</span>
                    </a>

                    {days <= 5 && <div className="header__search" style={{ width: 450, }}>
                        Account {days > 0 ? "Expires" : "Expired"} On : {expda.getDate()}/{expda.getMonth() + 1}/{expda.getFullYear()} - {days > 0 ? days+ " days":"" } &nbsp;
                        <button type="button" class="btn btn-danger btn-sm" onClick={() => { handleUnlock() }}>Subscribe Now</button>
                    </div>}

                    {/* <div className="header__search" style={{width:450,}}>
            Select Speciality : 
                <select className="header__input" value={sid} onChange={handleQb} style={{width:70+"%"}}>
                    <option value="">All</option>
                    {
                        subjects.map((obj)=>{
                            
                            return (<option value={obj.id}>{obj.name}</option>)
                        })
                    }
                </select>
            </div> */}
                    <div>
                        <a href="/contact-us" className="btn btn-outline-secondary btn-sm">
                            <i className='bx bx-help-circle'></i>
                        </a>
                    </div>
                    <div className="header__toggle">
                        <i className="bx bx-menu" id="header-toggle"></i>
                    </div>
                </div>
                <div className="breadcrumb__container">
                    <div className="d-none d-lg-block">
                        <div className="row w-100 justify-content-between align-items-center">
                            <div className="col-sm-6">
                                <div className="d-flex align-items-center">
                                    <div className="border-end border-secondary pe-3 me-3 fw-bold">{breadcrumb}</div>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-0">
                                            <li className="breadcrumb-item"><a href="/dashboard"></a></li>
                                            {/* <!-- <li className="breadcrumb-item active" aria-current="page">Library</li> --> */}
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                {/* <div className="d-flex align-items-center">
                            <div className="header__search me-3">
                                <select className="header__input">
                                    <option value="">Browse Question</option>
                                </select>
                            </div>
                            <div className="header__search searchbox">
                                <input type="search" placeholder="Search" className="header__input"/>
                                <i className="bx bx-search header__icon"></i>
                            </div>
                        </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div id="sidebar">
                <div className="nav" id="navbar">
                    <nav className="nav__container">
                        <div>
                            <a href="#" className="nav__link nav__logo">
                                {/* <i className="bx bxs-disc nav__icon"></i> */}
                                {/* <img src="/assets/images/ENT-Final-Logo.png" style={{height:30,width:30,}}/> */}
                                <span className="nav__logo-name">Metal Quiz</span>
                            </a>
                            <div className="nav__list">
                                <div className="nav__items">
                                    <h3 className="nav__subtitle">Menu</h3>
                                    <a href="/dashboard" className="nav__link active">
                                        <i className="bx bx-home nav__icon"></i>
                                        <span className="nav__name">Dashboard</span>
                                    </a>

                                    {days > 0 && <div className="nav__dropdown">
                                        <a href="#" className="nav__link">
                                            <i className='bx bx-test-tube nav__icon'></i>
                                            <span className="nav__name">Quizzes</span>
                                            <i className="bx bx-chevron-down nav__icon nav__dropdown-icon"></i>
                                        </a>
                                        <div className="nav__dropdown-collapse">
                                            <div className="nav__dropdown-content">
                                                {/* <a href="/dashboard/custom-quiz" className="nav__dropdown-item">New Custom Quiz</a>
                                                <a href="/dashboard/mock-quiz" className="nav__dropdown-item">Mock Quiz</a> */}
                                                {/* <a href="/dashboard/quick-quiz" className="nav__dropdown-item">Start Quiz</a> */}
                                                <a href="/dashboard/my-quiz" className="nav__dropdown-item">My Recent Quizzes</a>
                                            </div>
                                        </div>
                                    </div>}
                                    <div className="nav__dropdown">
                                        <a href="#" className="nav__link">
                                            <i className='bx bx-line-chart nav__icon'></i>
                                            <span className="nav__name">Performance</span>
                                            <i className="bx bx-chevron-down nav__icon nav__dropdown-icon"></i>
                                        </a>
                                        <div className="nav__dropdown-collapse">
                                            <div className="nav__dropdown-content">
                                                <a href="/dashboard/performance-timeline" className="nav__dropdown-item">Performance</a>
                                                {/* <a href="/dashboard/performance" className="nav__dropdown-item">Speciality</a> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="nav__items">
                                    <h3 className="nav__subtitle">Profile</h3>
                                    <div className="nav__dropdown">
                                        <a href="#" className="nav__link">
                                            <i className="bx bx-cog nav__icon"></i>
                                            <span className="nav__name">Settings</span>
                                            <i className="bx bx-chevron-down nav__icon nav__dropdown-icon"></i>
                                        </a>
                                        <div className="nav__dropdown-collapse">
                                            <div className="nav__dropdown-content">
                                                <a href="/dashboard/settings" className="nav__dropdown-item">Settings</a>
                                                {/* <a href="/dashboard/settings" className="nav__dropdown-item">My Subscription</a> */}
                                                {/* <a href="/dashboard/settings" className="nav__dropdown-item">Reset Password</a>
                                    <a href="/dashboard/settings" className="nav__dropdown-item">Access Codes</a> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a href="/logout" className="nav__link nav__logout">
                            <i className="bx bx-log-out nav__icon"></i>
                            <span className="nav__name">Log Out</span>
                        </a>
                    </nav>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Dashboard;