import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { useParams } from "react-router-dom";
import Pricing from "./Pricing";
import "./Pricing.css";
import { BiCheckboxSquare } from "react-icons/bi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PricingTable, PricingSlot, PricingDetail } from 'react-pricing-table';
import Button from 'react-bootstrap/Button';
import { isEmail, isName, isMob, isPasss } from "./validators/Validations";
import Modal from 'react-bootstrap/Modal';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import MetaTags from 'react-meta-tags';
import SpinnerLoad from "./UIComponents/SpinnerLoad";

var CryptoJS = require("crypto-js");



function Subscribe() {
  const [loginError, setLoginError] = useState(false)
  const [uid, setUid] = useState(() => {
    const st = localStorage.getItem("info");
    return st || ""
  });
  const [service, setServices] = useState([]);
  const [packages, setPackage] = useState([]);
  const [isShow, setShow] = useState(false);
  const [pckg, setPckg] = useState('');
  const [price, setPrice] = useState('');
  const [orgprice, setOrgPrice] = useState('');
  const [drn, setDrn] = useState(null);
  const [coupon, setCoupon] = useState('');
  const [discount, setDiscount] = useState([]);
  const [mshow, setmShow] = useState(false);
  const [fname, setFname] = useState('');
  const [em, setEm] = useState('');
  const [job, setJob] = useState('');
  const [prof, setProf] = useState('United Kingdom');
  const [yr, setYr] = useState('January 2024');
  const [nameError, setNameError] = useState(false)
  const [emError, setEmError] = useState(false)
  const [termsconditions, setTermsConditions] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [isSumit,setSumit] = useState(false);
  const [days, setDays] = useState(() => {
    const st = localStorage.getItem("remaindays");
    return st || 0
  });

  const [logs, setLogs] = useState(() => {
    const st = localStorage.getItem("login");

    return st || ""
  });
  const initialOptions = {
    "client-id": "AWMmviigXSMiCI5iEh4Lb2C_p3iztV71nAr_r1mt60LHmJ9MJmJnrAVa-OxqB4g5Q7Knz_sB4zPffOFb",
    currency: "GBP",
  };

  function handleClose() {
    setmShow(!mshow);
  }

  function handleCheck(e) {
    //alert(e.target.checked);
    //console.log(e.target.checked);
    setTermsConditions(e.target.checked);
  }

  function handleRegister() {
    if (!fname) {
      toast.error("Please enter First Name");
      setNameError(true)
    }
    else if (!isEmail(em)) {
      toast.error("Please enter valid Email");
      setEmError(true);
    }
    else if (!termsconditions) {
      toast.error("Please Accept Terms and Condition");
      setTermsError(true)
    }
    else if (!job) {
      toast.error("Please Accept Terms and Condition");
      setTermsError(true)
    }

    else {
      setSumit(true)
      const data = {
        name: fname,
        email: em,
        job: job,
        prof: prof,
        anti: yr,
        type: 'user'
      }
      axios.post('https://api.fab-g.com/api/launch-reg', data)
        .then((res) => {
          const rdata = res.data;
          if (rdata.status == 'success') {
            // toast.success(rdata.msg);
            toast.success("Thank you for submitting your pre-registration form for our website. We will be in touch very soon with early bird access and a discount code to use our question bank.")
            setmShow(false);

          }
          setSumit(false)
        })
    }
  }
  //const sid = useParams();
  function handleLogin(scode, sdays, price) {


    setmShow(true)
    return false;
    var sdata = {
      "scode": scode,
      "days": sdays
    }
    localStorage.setItem('scodedata', JSON.stringify(sdata));
    if (!uid) {
      //setLoginError(true);
      toast.error('Please login to subscribe plan');

      localStorage.setItem('scodedata', JSON.stringify(sdata));
      window.location.href = "/register";
      return false;
    }
    setPckg(scode);
    // setDrn(parseInt(days) + parseInt(sdays));
    setDrn(sdays)
    // setPrice(price + '.00');
    setOrgPrice(price + '.00');
    setShow(true);
    //alert('testing');
    // var bytes = CryptoJS.AES.decrypt(sid.id.replace(/__/g,'/'), 'QB@123');
    // var pid = bytes.toString(CryptoJS.enc.Utf8);
    // console.log(pid)
    // var pid = "";

    // if (logs == "") {
    //   //setLoginError(true);
    //   toast.error('Please login to subscribe plan');
    //   return false;
    // }

    // let newDate = new Date()
    // let date = newDate.getDate();
    // let month = newDate.getMonth() + 1;
    // let year = newDate.getFullYear();

    // let separator = "/";
    // let dt = `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`;

    // let expdt = new Date(newDate.getTime() + days*24*60*60*1000)

    // date = expdt.getDate();
    // month = expdt.getMonth() + 1;
    // year = expdt.getFullYear();

    // let exp = `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`;
    // const sdata = {
    //   package_id: pid,
    //   sub_startdate:dt,
    //   sub_enddate:exp,
    //   sub_code: scode,
    // }
    // console.log(sdata);

    // axios.post("https://api.fab-g.com/api/subscription", sdata,{headers:{"Authorization" : `Bearer ${uid}`}})
    //   .then((res) => {
    //     const data = res.data;
    //     console.log(data);
    //     if (data.status == "success") {
    //       localStorage.setItem('subid', pid);
    //       localStorage.setItem('scode', scode);
    //       window.location.href = "/dashboard"
    //     }
    //     console.log(res);
    //   })
  }
  function featchPackage() {
    // var bytes = CryptoJS.AES.decrypt(sid.id.replace(/__/g,'/'), 'QB@123');
    //   var pid = bytes.toString(CryptoJS.enc.Utf8);
    axios.get('https://api.fab-g.com/api/packages')
      .then((res) => {
        const data = res.data;
        console.log('packages', data);
        setPackage(data);
      })
  }
  function handleDiscount() {
    // var prc = parseFloat(orgprice) - parseFloat(coupon);
    // setPrice(prc)
    if (!coupon) {
      toast.warning('Invalid coupon code');
      return;
    }
    let pData = {
      cpn: coupon,
    }
    axios.post('https://api.fab-g.com/api/coupon/check', pData, { headers: { "Authorization": `Bearer ${uid}` } })
      .then((res) => {
        console.log(res.data)
        var data = res.data;

        if (data.length > 0) {
          let eedt = data[0].dis_end_date.split('/');
          var sdt = new Date();
          var edt = new Date(eedt[2] + '-' + eedt[1] + '-' + eedt[1]);
          var time_difference = edt.getTime() - sdt.getTime();

          //calculate days difference by dividing total milliseconds in a day  
          var days_difference = parseInt(time_difference / (1000 * 60 * 60 * 24)) + 1;
          console.log(edt);
          if (days_difference <= 0) {
            toast.error('Coupon code expired');
            return;
          }
          setDiscount(data);
          data = data[0];
          if (data.dis_type == "amount") {
            var prc = parseFloat(orgprice) - parseFloat(data.dis_amt);
            setPrice(prc + '.00')
          }
          else {
            var dis = parseFloat(orgprice) * (parseFloat(data.dis_amt / 100))
            var prc = parseFloat(orgprice) - dis;
            setPrice(prc.toFixed(2) + "")
          }
        }
        else {
          toast.error('Coupon code expired');

        }

      })
  }
  function handleContinue() {
    setPrice(orgprice);
  }
  function featchService() {
    axios.get('https://api.fab-g.com/api/getservice')
      .then((res) => {
        const data = res.data;
        // console.log(data);
        setServices(data);
      })
  }
  function AddCC(urlOfTheLibrary) {
    const script = document.createElement('link');
    script.href = urlOfTheLibrary;
    script.rel = "stylesheet";
    script.type = "text/css";
    document.head.appendChild(script);
  }
  const whitebg = {
    backgroundColor: 'white'
  }
  const recommend = {
    backgroundColor: '#0d5c632c'
  }
  useEffect(() => {
    if (!uid) {
      //setLoginError(true);
      toast.error('Please login to Start Exam');

      // localStorage.setItem('scodedata', JSON.stringify(sdata));
      window.location.href = "/login";
      // return false;
    }
    else{
      window.location.href = '/dashboard'
    }
    /* featchService();
    featchPackage(); */
  }, [])
  return (

    <React.Fragment>
      {AddCC('/assets/css/pricing.css')}
      {AddCC('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css')}
      <ToastContainer />
      <MetaTags>
                <title>FRCS (ORL-HNS) Exam Preparation: Subscription Available</title>
                <meta name="description" content="Subscribe for FRCS (ORL-HNS) MCQs and access comprehensive exam preparation materials. Choose your subscription plan today for success.Select Your FRCS (ORL-HNS) Plan" />
                <meta property="og:title" content="FRCS (ORL-HNS) Exam Preparation MCQ Subscription" />
                <meta property="keywords" content="family medicine question bank free, internal medicine board questions, general surgery question bank, sample questions, exam review questions, surgery question bank,  FRCS exam preparation resource, ENT FRCS exams, ent mcq, mcq ent, Frcs" />
                <link rel="canonical" href={window.location.href}/>
            </MetaTags>
      <div class="container-fluid">
        <div class="row">
          <Header />
          <Modal show={mshow} onHide={handleClose}
            size={'lg'}
          >

            <Modal.Body>
              <div style={{ position: 'absolute', paddingRight: 32, width: "100%" }}>
                <span style={{ float: 'right', background: '#000', color: '#fff', borderRadius: 5, padding: 5, }} onClick={handleClose}>X</span>
              </div>
              {/* <p>Quiz Completed!</p> */}

              <div class="col-sm-12 " style={{ background: '#188ccc' }}>
                <img src="/assets/images/bg_lightbox.png" class="img-fluid" />
              </div>
              <div class="col-sm-12" style={{ padding: 10, }}>

                <h1 style={{ fontWeight: 'bold', fontSize: 40, marginTop: 15, textAlign: 'left' }}>Exciting News - Launching Soon!</h1>
                <p style={{ marginTop: 20, }}>We are very excited to announce that access to our FRCS (ORL-HNS) exam specific, evidence based question bank will be available very soon. To receive an alert and early bird access with a special launch discount code, please complete the following pre-registration information:</p>
                <div class="row">
                  <div class="col-sm-6 mb-3">
                    <label class="form-label">Full Name <span class="text-danger">* {nameError ? ("Invalid Name") : ("")}</span></label>
                    <input type="text" class="form-control" placeholder="Full Name" value={fname} onChange={(fname) => {
                      setFname(fname.target.value)
                      setNameError(false)
                    }} />
                  </div>
                  <div class="col-sm-6 mb-3">
                    <label class="form-label">Email ID <span class="text-danger">* {emError ? ("Invalid Email") : ("")}</span></label>
                    <input type="email" class="form-control" placeholder="Email ID" value={em} onChange={(em) => {
                      setEm(em.target.value)
                      setEmError(false)
                    }} />
                  </div>
                  <div class="col-sm-6 mb-3">
                    <label class="form-label">Job Title <span class="text-danger">* {nameError ? ("Invalid Name") : ("")}</span></label>
                    <input type="text" class="form-control" placeholder="Job Title" value={job} onChange={(fname) => {
                      setJob(fname.target.value)
                      // setNameError(false)
                    }} />
                  </div>
                  <div class="col-sm-6 mb-3">
                    <label class="form-label">Region of Training<span class="text-danger">*</span></label>
                    <select class="form-select" value={prof} onChange={(prof) => {
                      setProf(prof.target.value)
                      // setPract("");
                    }}>

                      <option value="United Kingdom">United Kingdom</option>
                      <option value="International">International</option>
                    </select>
                  </div>
                  <div class="col-sm-6 mb-3">
                    <label class="form-label">Anticipated Exam Sitting<span class="text-danger">*</span></label>
                    <select class="form-select" value={yr} onChange={(yr) => {
                      setYr(yr.target.value)
                      // setPract("");
                    }}>

                      <option value="January 2024/">January 2024</option>
                      <option value="March 2024 (International)/">March 2024 (International)</option>
                      <option value="July 2024">July 2024</option>
                      <option value="Undecided">Undecided</option>
                    </select>
                  </div>
                </div>
                <div class="mb-3">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" onChange={handleCheck} id="flexCheckDefault" />
                    <label class="form-check-label" for="flexCheckDefault">
                      I agree with the <a href="/terms-conditions" class="text-main" >Terms & Conditions</a>
                      <span class="text-danger">* {termsError ? ("Please accept terms & conditions") : ("")}</span>
                    </label>
                  </div>
                </div>
                {isSumit?<SpinnerLoad/> : <button type="button" class="btn btn-main" onClick={handleRegister}>Register</button>}
              </div>

            </Modal.Body>

          </Modal>
        </div>
      </div>

      {/* <div class="container-fluid" id="aboutus">
        <div class="row">
          <div class="col-sm-12">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-sm-12 d-flex justify-content-between">
                  <h1>Services Available</h1>
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0" id="breadcrumb">
                      <li class="breadcrumb-item"><a href="/">Home</a></li>
                      <li class="breadcrumb-item active" aria-current="page">Subscribe</li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div class="container pb-5" id="main-content">
        {isShow ? <React.Fragment>
          <section>
            <div class="container py-5">

              <header class="text-center mb-5 text-white">
                <div class="row">
                  <div class="col-lg-7 mx-auto" style={{ color: 'black' }}>
                    <h1>Subscription selected</h1>
                    <p>For The FRCS (ORL-HNS) Section One Examination.</p>
                  </div>
                </div>
              </header>
              <div class="row text-center align-items-end">
                <div class="col-lg-4"></div>

                <div class="col-lg-4 mb-5 mb-lg-0 pricingTable">
                  <div class="bg-white p-5 rounded-lg shadow">
                    <h1 class="text-uppercase font-weight-bold mb-2">{pckg}</h1>
                    <h2 class="h1 font-weight-bold">£{orgprice}<span class="text-small font-weight-normal ml-2"> / {drn} days</span></h2>
                    <div class="row">
                      <div class="col-sm-6">
                        <input type="text" class="form-control" placeholder="Coupon code" value={coupon} onChange={(e) => {
                          setCoupon(e.target.value);
                        }} />
                      </div>
                      <div class="col-sm-6">
                        <button type="button" class='btn btn-main' onClick={handleDiscount}>Apply code</button>
                      </div>
                    </div>
                    {discount.length > 0 && <><h1 class="mt-5">Congratulations</h1>
                      <h3>you have discount of {discount[0].dis_type == "amount" ? "£" + discount[0].dis_amt + "" : discount[0].dis_amt + "%"}</h3></>}
                    {price && <h2 class="mt-5">Confirm to Pay {orgprice != price && "£" + price}</h2>}
                    {price ? <PayPalScriptProvider options={initialOptions}>
                      <PayPalButtons style={{ layout: "horizontal" }}
                        createOrder={(data, actions) => {
                          return actions.order.create({
                            purchase_units: [
                              {
                                amount: {
                                  value: price,
                                },
                              },
                            ],
                          });
                        }}
                        onApprove={(data, actions) => {
                          return actions.order.capture().then((details) => {

                            // console.log(details);
                            var pid = "";


                            let newDate = new Date()
                            let date = newDate.getDate();
                            let month = newDate.getMonth() + 1;
                            let year = newDate.getFullYear();

                            let separator = "/";
                            let dt = `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`;
                            let drns = drn + days
                            let expdt = new Date(newDate.getTime() + drn * 24 * 60 * 60 * 1000)

                            date = expdt.getDate();
                            month = expdt.getMonth() + 1;
                            year = expdt.getFullYear();

                            let exp = `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`;
                            var scodedata = JSON.parse(localStorage.getItem('scodedata'));
                            const sdata = {
                              package_id: pid,
                              sub_startdate: dt,
                              sub_enddate: exp,
                              sub_code: pckg,
                              resp: details,
                              amt: price,
                              days: scodedata.days,
                            }
                            console.log(sdata);

                            axios.post("https://api.fab-g.com/api/subscription", sdata, { headers: { "Authorization": `Bearer ${uid}` } })
                              .then((res) => {
                                const data = res.data;
                                console.log(data);
                                if (data.status == "success") {
                                  toast.success("Payment done successfully")
                                  scodedata = localStorage.getItem('scodedata');
                                  localStorage.clear();
                                  localStorage.setItem('response', JSON.stringify(details))
                                  localStorage.setItem('subid', pid);
                                  localStorage.setItem('scode', pckg);

                                  localStorage.setItem('scodedata', scodedata)
                                  localStorage.removeItem('info')
                                  window.location.href = '/thank-you'

                                }
                                console.log(res);
                              })

                          });
                        }}
                        onCancel={(data) => {
                          window.location.reload();
                        }}
                        onError={(data) => {
                          window.location.reload();
                        }} />
                    </PayPalScriptProvider>
                      :
                      <div>
                        <p class="mt-3" style={{ textAlign: "center" }} >OR</p>
                        <button type="button" class='btn btn-main mt-2' onClick={handleContinue}>Continue to Pay</button>
                      </div>
                    }
                  </div>
                </div>

              </div>

            </div>
          </section>

        </React.Fragment>
          :
          <section>
            <div class="container py-5">

              <header class="text-center mb-5 text-white">
                <div class="row">
                  <div class="col-lg-7 mx-auto" style={{ color: 'black' }}>
                    <h1>Subscriptions Available</h1>
                    <p>Prepare For The FRCS (ORL-HNS) Section One Examination.</p>
                  </div>
                </div>
              </header>



              <div class="row text-center align-items-end">


                {
                  packages.map((obj) => {

                    return (
                      <div class="col-lg-4 mb-5 mb-lg-0">
                        <div class="p-5 rounded-lg shadow" style={obj.package_name == "Premium" ? recommend : whitebg}>
                          {obj.package_name == "Premium" && <h2 class="h4 text-uppercase font-weight-bold mb-4">Recommended</h2>}
                          <h1 class="h6 text-uppercase font-weight-bold mb-4">{obj.package_name}</h1>
                          <h2 class="h1 font-weight-bold">£{obj.pricing}<span class="text-small font-weight-normal ml-2">/ {obj.duration} days</span></h2>

                          {obj.package_name == "Premium" ? <div class="custom-separator my-4 mx-auto bg-primary"></div> :
                            <div class="custom-separator my-4 mx-auto bg-primary-color"></div>}

                          <ul class="list-unstyled my-5 text-small text-left">
                            {
                              service.map((objs) => {
                                var ids = obj.service_include;
                                if (ids.includes(objs.id)) {
                                  return (
                                    <li class="mb-3">
                                      <i class="fa fa-check mr-2 text-primary"></i> {objs.service_name}</li>
                                  )
                                }
                                else {
                                  return (<li class="mb-3 text-muted">
                                    <i class="fa fa-times mr-2"></i>
                                    <del>{objs.service_name}</del>
                                  </li>)
                                }
                              })
                            }
                            {/* <li class="mb-3">
                              <i class="fa fa-check mr-2 text-primary"></i> Lorem ipsum dolor sit amet</li>
                            <li class="mb-3">
                              <i class="fa fa-check mr-2 text-primary"></i> Sed ut perspiciatis</li>
                            <li class="mb-3">
                              <i class="fa fa-check mr-2 text-primary"></i> At vero eos et accusamus</li>
                            <li class="mb-3 text-muted">
                              <i class="fa fa-times mr-2"></i>
                              <del>Nam libero tempore</del>
                            </li>
                            <li class="mb-3 text-muted">
                              <i class="fa fa-times mr-2"></i>
                              <del>Sed ut perspiciatis</del>
                            </li>
                            <li class="mb-3 text-muted">
                              <i class="fa fa-times mr-2"></i>
                              <del>Sed ut perspiciatis</del>
                            </li> */}
                          </ul>
                          <button class="btn btn-main btn-block p-2 shadow " onClick={() => handleLogin(obj.package_name, obj.duration, obj.pricing)}>Subscribe</button>
                        </div>
                      </div>
                    )
                  })}
                {/* <div class="col-lg-4 mb-5 mb-lg-0">
                  <div class="bg-white p-5 rounded-lg shadow">
                    <h1 class="h6 text-uppercase font-weight-bold mb-4">Pro</h1>
                    <h2 class="h1 font-weight-bold">£399<span class="text-small font-weight-normal ml-2">/ month</span></h2>

                    <div class="custom-separator my-4 mx-auto bg-primary-color"></div>

                    <ul class="list-unstyled my-5 text-small text-left font-weight-normal">
                      <li class="mb-3">
                        <i class="fa fa-check mr-2 text-primary"></i> Lorem ipsum dolor sit amet</li>
                      <li class="mb-3">
                        <i class="fa fa-check mr-2 text-primary"></i> Sed ut perspiciatis</li>
                      <li class="mb-3">
                        <i class="fa fa-check mr-2 text-primary"></i> At vero eos et accusamus</li>
                      <li class="mb-3">
                        <i class="fa fa-check mr-2 text-primary"></i> Nam libero tempore</li>
                      <li class="mb-3">
                        <i class="fa fa-check mr-2 text-primary"></i> Sed ut perspiciatis</li>
                      <li class="mb-3 text-muted">
                        <i class="fa fa-times mr-2"></i>
                        <del>Sed ut perspiciatis</del>
                      </li>
                    </ul>
                    <a href="#" class="btn btn-primary btn-block p-2 shadow rounded-pill">Subscribe</a>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="bg-white p-5 rounded-lg shadow">
                    <h1 class="h6 text-uppercase font-weight-bold mb-4">Enterprise</h1>
                    <h2 class="h1 font-weight-bold">£899<span class="text-small font-weight-normal ml-2">/ month</span></h2>

                    <div class="custom-separator my-4 mx-auto bg-primary"></div>

                    <ul class="list-unstyled my-5 text-small text-left font-weight-normal">
                      <li class="mb-3">
                        <i class="fa fa-check mr-2 text-primary"></i> Lorem ipsum dolor sit amet</li>
                      <li class="mb-3">
                        <i class="fa fa-check mr-2 text-primary"></i> Sed ut perspiciatis</li>
                      <li class="mb-3">
                        <i class="fa fa-check mr-2 text-primary"></i> At vero eos et accusamus</li>
                      <li class="mb-3">
                        <i class="fa fa-check mr-2 text-primary"></i> Nam libero tempore</li>
                      <li class="mb-3">
                        <i class="fa fa-check mr-2 text-primary"></i> Sed ut perspiciatis</li>
                      <li class="mb-3">
                        <i class="fa fa-check mr-2 text-primary"></i> Sed ut perspiciatis</li>
                    </ul>
                    <a href="#" class="btn btn-primary btn-block p-2 shadow rounded-pill">Subscribe</a>
                  </div>
                </div> */}

              </div>
            </div>
          </section>


        }
      </div>
      <Footer />

    </React.Fragment>
  )
}

export default Subscribe;

