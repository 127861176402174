import React, { useEffect, useState } from "react";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import RadioOpt from "./RadioOpt";

const QuizQst = (pros) => {

    let i = 0;
    const [qdata, setQdata] = useState(pros.qdata);
    const [bank, setBank] = useState(pros.bank)
    const [question, setQuestion] = useState(pros.question);
    const [answer, setAnswer] = useState(pros.answer);
    const [cans, setCAns] = useState('');
    const [currentQuestion, setCurrentQuestion] = useState(pros.cq);
    const [btnname, setBtnname] = useState('Next');
    const [show, setShow] = useState(false);
    const [review, setReview] = useState(false);
    const [ansStatus, setAnsStatus] = useState('')
    const [pcnt, setpcnt] = useState(0)
    const [percent, setPercentage] = useState(0)
    const [isPause, setPause] = useState(false);
    const [showExplain, setExplain] = useState(false);
    let cnt = 0;

    const handleNext = () => {
        cnt = currentQuestion;
        setAnsStatus("");
        if (bank.length > currentQuestion + 1) {
            cnt++;
            setCurrentQuestion(cnt);

            setQuestion(bank[cnt].question);
            setAnswer(bank[cnt].answers);
            if (cnt + 1 == bank.length) {
                setBtnname('Submit')
            }
            else {
                setBtnname('Next')
            }
            // console.log(cnt);
        }
        else {
            setShow(true);

        }


    }
    const autSubmit = () => {
        const resp = {
            ans: bank,
        }
        console.log(resp);
        axios.post('https://api.fab-g.com/api/quiz_answer', resp)
            .then((resp) => {
                //alert('Quiz ended successfully');
                console.log(resp);
                const data = resp.data;
                if (data.status = "success") {
                    window.location.href = "/Dashboard";
                }
                //window.location.href="/Dashboard";
            })
    }
    function handleComplete() {
        var rtm = localStorage.getItem('sec');
        var tans = 0
        var twrong = 0
        var tunsen = 0;
        bank.map((obj) => {
            if (obj.question.answer_status == "correct") {
                tans++;
            }
            else {
                twrong++;
            }
        })
        var p = (parseFloat(tans) / parseFloat(parseFloat(bank.length))) * 100;
        const resp = {
            ans: bank,
            status:'completed',
            sec: rtm,
            perc:p.toFixed(2)
        }
        const uid = localStorage.getItem('info');
        // console.log(resp);
        axios.post('https://api.fab-g.com/api/quiz_answer', resp,{ headers: { "Authorization": `Bearer ${uid}` } })
            .then((resp) => {
                //alert('Quiz ended successfully');
                console.log(resp);
                const data = resp.data;
                if (data.status = "success") {
                    // window.location.href = "/Dashboard";
                    localStorage.setItem("qdata", JSON.stringify(qdata))
                        localStorage.setItem("bank", JSON.stringify(bank));
                        window.location.href = "/dashboard/review"
                }
                //window.location.href="/Dashboard";
            })
    }
    const handlePos = (e, pos) => {
        console.log(e);
        setAnsStatus("");
        pos = e.target.value - 1;
        if (bank.length > pos) {
            cnt = pos;
            setCurrentQuestion(cnt);
            setQuestion(bank[cnt].question);
            setAnswer(bank[cnt].answers);
            if (cnt + 1 == bank.length) {
                setBtnname('Submit')
            }
            else {
                setBtnname('Next')
            }
            console.log(cnt);
        }
    }
    function handlePrevious() {
        // setQuestion(bank[0].question);
        //     setAnswer(bank[0].answers);
        cnt = currentQuestion;
        setAnsStatus("");
        if (currentQuestion >= 0) {

            cnt--;
            console.log(cnt);
            setCurrentQuestion(cnt);
            setQuestion(bank[cnt].question);
            setAnswer(bank[cnt].answers);
            if (cnt + 1 == bank.length) {
                setBtnname('Submit')
            }
            else {
                setBtnname('Next')
            }

        }
    }

    function handleAnsChange(e) {
        console.log(e.target.value);
        let ans = e.target.value;
        setCAns(ans)
        console.log(cans);
        var t = pcnt;
        t = t + 1;
        setpcnt(t);
        var per = ((currentQuestion + 1) / bank.length) * 100
        setPercentage("" + per)
        console.log('percent', per)
        bank[currentQuestion].question.user_ans = e.target.value;
        if (bank[currentQuestion].question.answer_option == ans) {
            bank[currentQuestion].question.answer_status = 'correct';
            setAnsStatus("Correct");
        }
        else {
            bank[currentQuestion].question.answer_status = 'incorrect';
            setAnsStatus("Incorrect");
        }

        var rtm = localStorage.getItem('sec');
        const resp = {
            ans: bank,
            status: 'active',
            sec:rtm,
            perc:0
        }
        console.log(resp);
        const uid = localStorage.getItem('info');
        
        axios.post('https://api.fab-g.com/api/quiz_answer', resp, { headers: { "Authorization": `Bearer ${uid}` } })
            .then((resp) => {
                //alert('Quiz ended successfully');
                console.log(resp);
                const data = resp.data;
                if (data.status = "success") {
                    localStorage.removeItem('bank');
                    localStorage.removeItem('qdata');
                    // window.location.href = "/Dashboard";
                }
                //window.location.href="/Dashboard";
                // window.location.href = '/dashboard/my-quiz'
            })
        console.log(bank);

    }
    function handleStop() {

    }
    function handlepause() {
        //setQTimer(qtimer)
        //calcTimeDelta.onPause
        // console.log('clicked');
        var rtm = localStorage.getItem('sec');
        const resp = {
            ans: bank,
            status: 'active',
            sec:rtm,
            perc:0
        }
        console.log(resp);
        const uid = localStorage.getItem('info');
        axios.post('https://api.fab-g.com/api/quiz_answer', resp, { headers: { "Authorization": `Bearer ${uid}` } })
            .then((resp) => {
                //alert('Quiz ended successfully');
                console.log(resp);
                const data = resp.data;
                if (data.status = "success") {
                    localStorage.removeItem('bank');
                    localStorage.removeItem('qdata');
                    window.location.href = "/Dashboard";
                }
                //window.location.href="/Dashboard";
                // window.location.href = '/dashboard/my-quiz'
            })


    }
    const handleClose = () => setShow(false);
    const handleReview = () => setReview(false);
    
    useEffect(() => {
        
        //console.log(question);
        // setQuestion(bank[currentQuestion].question);
        // setAnswer(bank[currentQuestion].answers);
    }, [])
    return (
        <React.Fragment>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title ><p>Congratulations !!!</p></Modal.Title>
                </Modal.Header>
                <Modal.Body><p>Quiz Completed!</p></Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setShow(false)
                        // setReview(true)
                    }}>
                        Review Quiz
                    </Button>
                    <Button variant="primary" style={{ backgroundColor: "#0d5c63", }} onClick={() => {
                        //setReview(true)
                        handleComplete();
                    }}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={review} onHide={handleReview} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title ><p>Quiz Review</p></Modal.Title>
                </Modal.Header>
                <Modal.Body><div>
                    {
                        bank.map((obj) => {
                            var cnt = 0;
                            var usrans = ""
                            return (
                                <div style={{ marginTop: 10, }}>
                                    Q. No. {cnt + 1} : <p style={{ fontSize: 16, marginBottom: 0, }} dangerouslySetInnerHTML={{ __html: obj.question.qname }}></p>
                                    Answers :
                                    {obj.answers.map((tobj) => {

                                        if (obj.question.user_ans == tobj.id) {
                                            usrans = "Your Ans : " + tobj.answer
                                            return (<li>{tobj.answer}</li>)
                                        }
                                        else {
                                            return (<li>{tobj.answer}</li>)
                                        }
                                    })}
                                    <b style={{ marginTop: 10, }}>{usrans}</b>
                                    <hr />
                                </div>
                            )
                        })
                    }
                </div></Modal.Body>
                <Modal.Footer>

                    <Button variant="primary" style={{ backgroundColor: "#0d5c63", }} onClick={handleComplete}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
            <div class="col-sm-2" id="left-buttons">
                <div class="d-flex align-items-start flex-column h-100">
                    <div class="btn-group-vertical mb-auto" role="group" aria-label="Vertical button group">
                        <div class="row align-items-center w-100 gx-2 d-none">
                            {/* <div class="col-sm-6">
                                        <button type="button" class="btn btn-light w-100" data-bs-toggle="modal"
                                            data-bs-target="#GradeModal"><i
                                                class='bx bxs-graduation me-2'></i>Grade</button>
                                    </div> */}
                            {isPause ? <div class="col-sm-6">
                                <button type="button" class="btn btn-light w-100" onClick={() => {

                                    pros.handlepause(true)
                                    setPause(false)
                                }}><i
                                    class='bx bx-play me-2' ></i>Start</button>
                            </div> :
                                <div class="col-sm-6">
                                    <button type="button" class="btn btn-light w-100" onClick={() => {
                                        pros.handlepause(false)
                                        setPause(true)
                                    }}><i
                                        class='bx bx-pause me-2' ></i>Pause</button>
                                </div>}
                            <div class="col-sm-6">
                                <button type="button" class="btn btn-light w-100" onClick={() => { handlepause() }}><i
                                    class='bx bx-stop me-2' ></i>Stop</button>
                            </div>

                        </div>
                        {/* <div class="btn-group" role="group">
                                    <button type="button" class="btn btn-light dropdown-toggle"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class='bx bx-edit-alt me-2'></i>Highlight
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li><a class="dropdown-item" href="#">Highlight</a></li>
                                        <li><a class="dropdown-item" href="#">Remove Highlight</a></li>
                                    </ul>
                                </div>
                                <button type="button" class="btn btn-light"><i
                                        class='bx bx-strikethrough me-2'></i>Strikeout</button>
                                <button type="button" class="btn btn-light" data-bs-toggle="modal"
                                    data-bs-target="#CalculatorModal"><i
                                        class='bx bx-calculator me-2'></i>Calculator</button>
                                <button type="button" class="btn btn-light" data-bs-toggle="modal"
                                    data-bs-target="#LabValuesModal"><i class='bx bx-test-tube me-2'></i>Lab
                                    Values</button>
                                <button type="button" class="btn btn-light" data-bs-toggle="modal"
                                    data-bs-target="#NoteModal"><i class='bx bx-notepad me-2'></i>Note</button>
                                <button type="button" class="btn btn-light">
                                    <div class="form-check mb-0">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                        <label class="form-check-label mb-0" for="flexCheckDefault">
                                            Mark
                                        </label>
                                    </div>
                                </button> */}
                    </div>

                    {/* <div class="small w-100 border-top pt-2">
                                <a href="#" class="text-main">Challenge a Friend</a>
                                <p class="mb-0 fw-bold mt-1">QID: 56906</p>
                            </div> */}
                </div>

            </div>
            <div class="col-sm-10 border-start">

                <div class="questions-section">
                    <div class="question">
                        <p style={{ color: '#0d5c63' }}>Q. No. {currentQuestion + 1} :</p> <p style={{ fontSize: 16 }} dangerouslySetInnerHTML={{ __html: question.qname }}></p>
                        {question.qtype =='image' ? <img src={"https://api.fab-g.com/image/"+question.img_link} style={{height:120,width:120}}/>
                        :question.qtype=="vdo" && <div dangerouslySetInnerHTML={{__html:question.vdo_link}}></div>
                    }
                    </div>
                    <div class="answers">
                        {
                            answer.map((obj) => {
                                
                                
                                // return (<RadioOpt obj={obj} handleAnsChange={handleAnsChange}/>)
                                // setCAns(question.user_ans)
                                return (
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name={"flexRadioDefault1" + obj.q_id}
                                            id={"flexRadioDefault1" + obj.id} onChange={handleAnsChange} value={obj.id} checked={obj.id == question.user_ans} />
                                        {obj.atype == "text" ? <label class="form-check-label" for={"flexRadioDefault1" + obj.id} style={{ color: 'black' }} dangerouslySetInnerHTML={{ __html: obj.answer }}>

                                        </label>
                                        :<img src={"https://api.fab-g.com/image/"+obj.answer} style={{height:120,width:120}}/>}
                                    </div>
                                )
                            })
                        }

                    </div>
                    <div class="d-flex mb-2 align-items-center">
                        {/* <button class="btn btn-main btn-sm me-4" data-bs-toggle="modal"
                                    data-bs-target="#CheckAnswerModal">Check Answer</button> */}
                        <button type='button' class="btn btn-main btn-sm me-4" onClick={handleNext}>{btnname}</button>
                        {/* {qdata.mode == "Tutor" && <span class="text-success fw-bold">{ansStatus}</span>} */}
                        {qdata.mode == "Study" && <span class="text-success fw-bold">{ansStatus}</span>}
                    </div>
                    {/* <p>
                                <span class="fw-bold">Graded Response : </span> Correct
                            </p> */}
                    <hr class="my-2" />

                    {qdata.mode == "Tutor" && (<button class="btn btn-light btn-sm text-main mb-2" onClick={() => setExplain(!showExplain)}>Show Explanation</button>)}
                    {qdata.mode == "Study" && (<button class="btn btn-light btn-sm text-main mb-2" onClick={() => setExplain(!showExplain)}>Show Explanation</button>)}
                    {showExplain && (
                        <React.Fragment><div class="row">

                            <div class="col-sm-12 border-end">
                                <p style={{ fontSize: 16 }} dangerouslySetInnerHTML={{ __html: question.explanation }}></p>
                            </div>
                            <div class="col-sm-5">

                            </div>
                        </div>
                            <hr class="my-2" /></React.Fragment>)}
                    <nav aria-label="Page navigation example" id="que-pages">
                        <ul class="pagination mb-0">
                            <li class="page-item">
                                <button type='button' class="btn btn-light btn-sm btn-main mb-2" href="#" onClick={handlePrevious} aria-label="Previous">
                                    <span aria-hidden="true">Previous</span>
                                </button>
                            </li>
                            {
                                bank.map((obj) => {
                                    i++
                                    if (!obj.question.user_ans) {
                                        return (
                                            <li class="page-item"><button type='button' class="page-link" onClick={(e) => { handlePos(e, i) }} value={i}>{i}</button></li>
                                        )
                                    }
                                    else {
                                        return (
                                            <li class="page-item"><button type='button' class="page-link" style={{ backgroundColor: '#0d5c63', color: 'white' }} onClick={(e) => { handlePos(e, i) }} value={i}>{i}</button></li>
                                        )
                                    }


                                })
                            }
                            {/* <li class="page-item active"><button type='button' class="page-link">1</button></li>
                            <li class="page-item"><button type='button' class="page-link" >2</button></li>
                            <li class="page-item"><button type='button' class="page-link" >3</button></li> */}
                            <li class="page-item">
                                <button type='button' class="btn btn-light btn-sm btn-main mb-2" href="#" aria-label="Next" onClick={handleNext}>
                                    <span aria-hidden="true">Next</span>
                                </button>
                            </li>
                        </ul>
                    </nav>
                    <div class="progress my-3" style={{ height: 8 }}>
                        {percent > 0 && <div class="progress-bar" role="progressbar" aria-label="Example 1px high"
                            style={{ width: percent + "%", backgroundColor: '#023e44' }} aria-valuenow={percent} aria-valuemin="0" aria-valuemax="100">
                        </div>}
                    </div>
                    <div class="row">
                        <div class="col-sm-7 border-end">
                            {/* Explaination */}
                        </div>
                        <div class="col-sm-5">
                            <div class="row">
                                <div class="col-sm-7">
                                    {/* <p class="mb-0 fw-bold">Peer Comparision</p>
                                            Chart */}
                                </div>
                                <div class="col-sm-5 d-none">
                                    {/* <p class="mb-0 fw-bold" style={{fontSize:16}}>Response Time</p>
                                            <p>00:06</p> */}
                                    <p class="mb-0 fw-bold" style={{ fontSize: 16 }}>Difficulty Level</p>
                                    <p class="text-capitalize">{question.qmode}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="explanation" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                aria-labelledby="CalculatorModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <p class="modal-title mb-0" id="CalculatorModalLabel">Explanation</p>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <p style={{ fontSize: 16 }} dangerouslySetInnerHTML={{ __html: question.explanation }}></p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default QuizQst;