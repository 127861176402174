import axios from "axios";
import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { isEmail, isName, isMob, isPasss } from "./validators/Validations";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from "./UIComponents/LoadingSpinner";
import MetaTags from 'react-meta-tags';



function Register() {

    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [mob, setMob] = useState('');
    const [em, setEm] = useState('');
    const [cem, setCem] = useState('');
    const [pass, setPass] = useState('');
    const [cpass, setCpass] = useState('');
    const [prof, setProf] = useState('Region (UK)');
    const [pract, setPract] = useState('East Midlands');
    const [termsconditions, setTermsConditions] = useState(false);
    const [nameError, setNameError] = useState(false)
    const [lnameError, setLnameError] = useState(false)
    const [mobError, setMobError] = useState(false)
    const [emError, setEmError] = useState(false)
    const [passError, setPassError] = useState(false)
    const [cpassError, setCPassError] = useState(false)
    const [termsError, setTermsError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [cityArr,setCityArr]=useState([]);

    var stateData = require('./utils/state.json')
    var cityData = require('./utils/cityState.json')

    function handleRegister() {

        //alert(prof);
        if (!isName(fname)) {
            toast.error("Please enter First Name");
            setNameError(true)
        }
        else if (!isName(lname)) {
            toast.error("Please enter Last Name");
            setLnameError(true)

        }
        else if (!isEmail(em)) {
            toast.error("Please enter valid Email");
            setEmError(true);
        }
        else if (!isMob(mob)) {
            toast.error("Please enter Mobile");
            setMobError(true)
        }
        else if (!isPasss(pass)) {
            toast.error("Password missing pattern");
            setPassError(true)
        }
        else if (pass != cpass) {
            toast.error("Please password Mismatch");
            setCPassError(true)
        }
        else if (!termsconditions) {
            toast.error("Please Accept Terms and Condition");
            setTermsError(true)
        }
        else {
            setLoading(true);
            let newDate = new Date()
            let date = newDate.getDate();
            let month = newDate.getMonth() + 1;
            let year = newDate.getFullYear();

            let separator = "/";
            let dt = `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`;
            const data = {
                name: fname + " " + lname,
                email: mob,
                password: pass,
                confirm_password: cpass,
                mob: mob,
                prof: prof,
                area: pract,
                usertype: 'Register'

            }

            axios.post('https://api.fab-g.com/api/signup', data)
                .then((res) => {
                    const data = res.data;
                    console.log(data);
                    if (data.status == "success") {
                        //alert("Registered Successfully");
                        const id = data.data.id;
                        if (!localStorage.getItem("scodedata")) {
                            axios.get("https://api.fab-g.com/sendmail/" + id)
                                .then((res) => {
                                    // const data=res.data;

                                    // console.log(res.data);
                                    // console.log('testing demo');



                                });
                            localStorage.setItem('register', true);
                            window.location.href = "/login"
                        }

                        else {
                            localStorage.setItem("info", data.data.token);
                            window.location.href = "/subscribe";
                        }

                    }
                    else {
                        setLoading(false)
                        toast.error(data.msg);
                        //alert(data.msg);
                    }
                })
                .catch((err)=>{
                    console.log(err);
                    setLoading(false)
                    toast.error('Server Error');
                })
            /*axios.post("https://api.fab-g.com/api/register", data,{headers:{'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS'}})
                .then((res) => {
                    const data = res.data;
                    console.log(data);
                    if (data.status == "success") {
                        //alert("Registered Successfully");
                        const id = data.data.id;
                        if (!localStorage.getItem("scodedata")) {
                            axios.get("https://api.fab-g.com/sendmail/" + id)
                                .then((res) => {
                                    // const data=res.data;

                                    console.log(res.data);
                                    // console.log('testing demo');

                                    localStorage.setItem('register', true);
                                    window.location.href = "/login"

                                });
                        }

                        else {
                            localStorage.setItem("info", data.data.token);
                            window.location.href = "/subscribe";
                        }

                    }
                    else {
                        setLoading(false)
                        toast.error(data.msg);
                        //alert(data.msg);
                    }
                })
             .catch((err) => {
                // console.log(err);
                setLoading(false)
                toast.error(data);
            }) */
            console.log(data);
        }


    }

    function handleCheck(e) {
        //alert(e.target.checked);
        //console.log(e.target.checked);
        setTermsConditions(e.target.checked);
    }

    /* useEffect(()=>{
        console.log(stateData);
    },[]) */

    return (
        <React.Fragment>
            <MetaTags>
                <title>Prepare for the FRCS (ORL-HNS) Exam with Our MCQs</title>
                <meta name="description" content="Monitor your progress and performance through detailed analytics and performance metrics, enabling you to track your improvement and strategize your study plan effectively." />
                <meta property="og:title" content="Unlock Your FRCS (ORL-HNS) Success with entmcq.com" />
                <meta property="keywords" content="frcs ent section one exam, frcs mcq exam, frcs sba exam" />
                <link rel="canonical" href={window.location.href}/>
            </MetaTags>
            {isLoading ? <LoadingSpinner /> : (<React.Fragment>
                <ToastContainer />

                <div class="container-fluid">
                    <div class="row">
                        <Header />
                    </div>
                </div>


                <div class="container my-5">

                    <div class="row align-items-center">
                        <div class="col-sm-4 bg-main p-4 d-none d-lg-block">
                            <img src="assets/images/login.svg" class="img-fluid" alt="Register" />
                        </div>
                        <div class="col-sm-8 p-4">
                            <div class="form-container">
                                <h3 class="mb-3">Register</h3>
                                <div class="row">
                                    <div class="col-sm-6 mb-3">
                                        <label class="form-label">First Name <span class="text-danger">* {nameError ? ("Invalid Name") : ("")}</span></label>
                                        <input type="text" class="form-control" placeholder="First Name" value={fname} onChange={(fname) => {
                                            setFname(fname.target.value)
                                            setNameError(false)
                                        }} />
                                    </div>
                                    <div class="col-sm-6 mb-3">
                                        <label class="form-label">Last Name <span class="text-danger">* {lnameError ? ("Invalid Name") : ("")}</span></label>
                                        <input type="text" class="form-control" placeholder="Last Name" value={lname} onChange={(lname) => {
                                            setLname(lname.target.value)
                                            setLnameError(false)
                                        }} />
                                    </div>

                                </div>
                                <div class="row">
                                    {/* <div class="col-sm-6 mb-3">
                                        <label class="form-label">Email ID <span class="text-danger">* {emError ? ("Invalid Email") : ("")}</span></label>
                                        <input type="email" class="form-control" placeholder="Email ID" value={em} onChange={(em) => {
                                            setEm(em.target.value)
                                            setEmError(false)
                                        }} />
                                    </div> */}
                                    <div class="col-sm-6 mb-3">
                                        <label class="form-label">Mobile Number <span class="text-danger">* {mobError ? ("Invalid Mobile") : ("")}</span></label>
                                        <input type="text" class="form-control" placeholder="Mobile Number" value={mob} onChange={(mob) => {
                                            setMob(mob.target.value)
                                            setMobError(false)
                                        }} />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 mb-3">
                                        <label class="form-label">Password <span class="text-danger">* {passError ? ("Password is not strong") : ("")}</span></label>
                                        <div class="input-group">
                                            <input type="password" class="form-control" placeholder="Password" value={pass} onChange={(pass) => {
                                                setPass(pass.target.value)
                                                setPassError(false)
                                            }} />
                                            {/* <button class="btn"><i class="bi bi-eye"></i></button> */}
                                        </div>
                                    </div>
                                    <div class="col-sm-6 mb-3">
                                        <label class="form-label">Password Confirmation <span
                                            class="text-danger">* {cpassError ? ("Password is not matching") : ("")}</span></label>
                                        <div class="input-group">
                                            <input type="password" class="form-control"
                                                placeholder="Password Confirmation" value={cpass} onChange={(cpass) => {
                                                    setCpass(cpass.target.value)
                                                    setCPassError(false);
                                                }} />
                                            {/* <button class="btn"><i class="bi bi-eye"></i></button> */}
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 mb-3">
                                        <label class="form-label">State<span class="text-danger">*</span></label>
                                        <select class="form-select" value={prof} onChange={(ele) => {
                                            setProf(ele.target.value)
                                            setPract("");
                                            const ct = cityData.citylist.filter((obj)=>obj.State == ele.target.value)
                                            setCityArr(ct);
                                        }}>
                                            <option value="">Select State</option>
                                            {stateData.statelist.map((st)=>{
                                                return(
                                                    <option value={st.State}>{st.State}</option>    
                                                )
                                            })}
                                            {/* <option value="Region (UK)">Region (UK)</option>
                                            <option value="International">International</option> */}
                                        </select>
                                    </div>
                                    <div class="col-sm-6 mb-3">
                                        <label class="form-label">City<span
                                            class="text-danger">* </span></label>
                                            <select class="form-select" value={pract} onChange={pract => setPract(pract.target.value)}>
                                                {cityArr.map((st)=>{
                                                    return(
                                                        <option value={st.city}>{st.city}</option>    
                                                    )
                                                })}
                                            </select>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" onChange={handleCheck} id="flexCheckDefault" />
                                        <label class="form-check-label" for="flexCheckDefault">
                                            I agree with the <a href="/terms-conditions" class="text-main" >Terms & Conditions</a>
                                            <span class="text-danger">* {termsError ? ("Please accept terms & conditions") : ("")}</span>
                                        </label>
                                    </div>
                                </div>
                                <button type="button" class="btn btn-main" onClick={handleRegister}>Register</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container py-4 d-none">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-sm-6 text-center text-lg-end">
                            <h4>To become a Question Author</h4>
                        </div>
                        <div className="col-sm-6 text-center text-lg-start">
                            <button className="btn login text-white" type="button" style={{ backgroundColor: '#0d5c63' }}
                                onClick={() => {
                                    window.location.href = "/questioner-register";
                                }}
                            >Register Here</button>
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment>)

            }
        </React.Fragment>
    )
}

export default Register;